export const PLANS = {
  BRONZE: {
    NAME: "Bronze",
    MONTHLY_PRICE: 0,
    YEARLY_PRICE: 0,
    BULLET_POINTS: [
      "Account Setup",
      `Access / use upto 5 forms (limit to optional forms)`,
      "One AI Assessment Allowed (Short form)",
    ],
  },

  SILVER: {
    NAME: "Silver",
    MONTHLY_PRICE: 99,
    YEARLY_PRICE: 999,
    BULLET_POINTS: [
      "Bronze features Plus",
      "Access all 50+ forms",
      "Generate As many AI Reports (short version)",
      "Store Reports / Data for upto 3 months",
    ],
  },
  GOLD: {
    NAME: "Gold",
    MONTHLY_PRICE: 399,
    YEARLY_PRICE: 3999,
    BULLET_POINTS: [
      `Silver features Plus`,
      "Report gets Psychologist review (1 per month)",
      "Get 30 min per month access to psychologist (online)",
      "Add Assessement of other reports eg., Blood, Other reports",
      "Store Reports / Data 1 year"
    ],
  }
};

export const PLANS_ARRAY = [PLANS.BRONZE, PLANS.SILVER, PLANS.GOLD];