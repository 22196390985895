import axios from 'axios';
import { toast } from 'react-hot-toast';
import { encryptData } from '../../utils/encryptionUtils'

const API_URL = process.env.REACT_APP_API_HOST

export const userLogin = (login) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'USER_LOGIN_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/login`, login);
            const encryptedToken = encryptData(data.token);
            localStorage.setItem('xat', encryptedToken);
            toast.success('User Logged in successfully!');
            dispatch({ type: 'USER_LOGIN_SUCCESS', user: data });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'USER_LOGIN_FAILURE', error: error.message });
        }
    }
}

export const psychologistLogin = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUB_LOGIN_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/psychologist/login`, user);
            localStorage.setItem('xat', data.token);
            toast.success('User Logged in successfully!');
            dispatch({ type: 'SUB_LOGIN_SUCCESS', user: data });
        }
        catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'SUB_LOGIN_FAILURE', error: error.message });
        }
    }
}


export const psychologistRegister = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUB_REGISTER_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/psychologist/register`, user);
            localStorage.setItem('xat', data.token);
            toast.success('User Logged in successfully!');
            dispatch({ type: 'SUB_REGISTER_SUCCESS', user: data });
        }
        catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'SUB_REGISTER_FAILURE', error: error.message });
        }
    }
}

export const patientRegister = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUB_REGISTER_REQUEST' });
            const { data } = await axios.post(`${API_URL}/auth/patient/register`, user);
            localStorage.setItem('xat', data.token);
            toast.success('User Logged in successfully!');
            dispatch({ type: 'SUB_REGISTER_SUCCESS', user: data });
        }
        catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'SUB_REGISTER_FAILURE', error: error.message });
        }
    }
}


export const createSubAccount = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SUB_ACCOUNT_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }

            const { data } = await axios.post(`${API_URL}/auth/sub-account`, user, config)
            toast.success("Sub Account Created Successfully!")
            dispatch({ type: 'SUB_ACCOUNT_SUCCESS', user: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'SUB_ACCOUNT_FAILURE', error: error.response.data.error });
        }
    }
}


export const getAllSubAccounts = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ALL_SUB_ACCOUNTS_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/auth/sub-account`, config)
            dispatch({ type: 'GET_ALL_SUB_ACCOUNTS_SUCCESS', payload: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'GET_ALL_SUB_ACCOUNTS_FAILURE', error: error.response.data.error });
        }
    }
}

export const logout = () => {
    return async (dispatch) => {
        try {
            localStorage.removeItem('xat')
			localStorage.setItem('logout', Date.now());
            dispatch({ type: 'LOGOUT' })
            toast.success('Logged out successfully!')
            window.location.href = 'https://web-stage.cliniscripts.com/login'
        }
        catch (error) {
            toast.error(error.response.data.error);
        }
    }
}

export const updateUser = (user, redirectToDashboard) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_USER_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/auth/profile`, user, config)
            dispatch({ type: 'UPDATE_USER_SUCCESS', user: data })
            toast.success('User Updated Successfully!')
            redirectToDashboard()
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'UPDATE_USER_FAILURE', error: error.response.data.error });
        }
    }
}


export const cancelSubscription = (planId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CANCEL_SUBSCRIPTION_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/payment/cancel`, { planId }, config)
            dispatch({ type: 'CANCEL_SUBSCRIPTION_SUCCESS', user: data.user })
            toast.success(data.message)
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'CANCEL_SUBSCRIPTION_FAILURE', error: error.response.data.error });
        }
    }
}


export const createSubscription = (sub, onPaymentCompleted) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CREATE_SUBSCRIPTION_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/payment`, { sub }, config)
            dispatch({ type: 'CREATE_SUBSCRIPTION_SUCCESS', user: data.user })
            toast.success(data.message)
            onPaymentCompleted()
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'CREATE_SUBSCRIPTION_FAILURE', error: error.response.data.error });
        }
    }
}


export const setFreeplan = (redirectToDashboard) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'SET_FREE_PLAN_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/payment/freeplan`, {}, config)
            dispatch({ type: 'SET_FREE_PLAN_SUCCESS', user: data.user })
            toast.success('You are set to free plan')
            redirectToDashboard()
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'SET_FREE_PLAN_FAILURE', error: error.response.data.error });
        }
    }
}

export const updateSubscription = (sub, onPaymentCompleted) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_SUBSCRIPTION_REQ' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem('xat')
                }
            }

            const { data } = await axios.post(`${API_URL}/payment/update/${sub.id}`, { sub }, config)
            dispatch({ type: 'UPDATE_SUBSCRIPTION_SUCCESS', user: data.user })
            toast.success(data.message)
            onPaymentCompleted()
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'UPDATE_SUBSCRIPTION_FAILURE', error: error.response.data.error });
        }
    }
}



export const getUserToken = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_USER_REQUEST' })
            const config = {
                headers: {
                    'x-auth-token': localStorage.getItem("xat")
                }
            }

            const { data } = await axios.get(`${API_URL}/auth/user`, config)

            dispatch({ type: 'GET_USER_SUCCESS', user: data.user })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'GET_USER_FAILURE', error: error.response.data.error });
        }
    }
}

export const verifyUser = (token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'VERIFY_USER_REQUEST' })
            const { data } = await axios.post(`${API_URL}/auth/verify-user`, { token })
            localStorage.setItem('xat', data.token);
            dispatch({ type: 'VERIFY_USER_SUCCESS', user: data })
        }
        catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'VERIFY_USER_FAILURE', error: error.response.data.error });
        }
    }
}