import React, { useEffect, forwardRef, useImperativeHandle, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import "./BraintreeDropIn.scss";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { createSubscription } from "../../app/actions/authActions";

const BraintreeDropIn = forwardRef(({ user, plan, onPaymentCompleted }, ref) => {
  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const dispatch = useDispatch();
  const subRef = useRef(null);

  useImperativeHandle(ref, () => ({
    click: () => {
      subRef.current.click();
    },
  }));

  const initializeBraintree = () => {
    const dropin = require("braintree-web-drop-in");
    dropin.create(
      {
        // insert your tokenization key or client token here
        authorization: "sandbox_ykd7n74g_m8kfxs9xgbszk2q8",
        container: "#braintree-drop-in-div",
      },
      function (error, instance) {
        if (error) console.error(error);
        else setBraintreeInstance(instance);
      }
    );
  };

  const handleOnClick = () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
          toast.error("Something went wrong");

        } else {
          const paymentMethodNonce = payload.nonce;
          const data = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            subId: user?.subscriptionId,
            plan: plan.choice,
            option: plan.isYearlyPlan ? "Yearly" : "Monthly",
            paymentMethodNonce,
            type: "user"
          }
          dispatch(createSubscription(data, onPaymentCompleted));
        }
      });
    }
  };


  useEffect(() => {
    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree();
      });
    } else {
      initializeBraintree();
    }
    //  eslint-disable-next-line
  }, []);

  return (
    <div className="braintree d-flex flex-column">
      <img
        className="braintree__logo"
        src="./assets/braintree-logo.png"
        alt="braintree powered by paypal"
      />
      <div id="braintree-drop-in-div" />

      <Button
        className="braintree__button w-100 d-none"
        type="primary"
        disabled={!braintreeInstance || !plan?.choice}
        // onClick={user.subscriptionId !== undefined ? handleUpdate : handleOnClick}
        onClick={handleOnClick}
        ref={subRef}
      >
        Subscribe
      </Button>

    </div>
  );
});


export default BraintreeDropIn;