import { React, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Plan from './pages/Plan';
import HeaderLayout from './components/HeaderLayout';
import Protected from './components/Protected';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from './app/actions/authActions';
import Payment from './pages/Payment';
import Page404 from './components/404/Page404';
import VerifyUser from './pages/VerifyUser';
import { getForms } from './app/actions/formActions';
import PendingReportList from './pages/Reports/PendingReportList';
import EditPendingReport from './pages/Reports/EditPendingReport';
import { getAssessments, resetReviewLimit, resetTimeLimit } from './app/actions/assessActions';
import Home from './components/404/Home';

// const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const Assessment = lazy(() => import('./pages/Assesment'));
function App() {
  console.log("Build Date: 24 June 2024")

  const dispatch = useDispatch()
  const { isAuthenticated, user } = useSelector(state => state.Auth)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserToken())
      dispatch(getForms())
      dispatch(getAssessments())
      if (user?.planId === 'assess-silver-monthly-patient' || user?.planId === 'assess-silver-yearly-patient') {
        dispatch(resetTimeLimit())
      }
      if (user?.planId === 'assess-gold-monthly-patient' || user?.planId === 'assess-gold-yearly-patient') {
        dispatch(resetReviewLimit())
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    const handleLogout = (event) => {
      if (event.key === 'logout') {
        localStorage.removeItem('xat')
        window.location.href = 'https://web-stage.cliniscripts.com/login'
      }
    };
    window.addEventListener('storage', handleLogout);

    return () => {
      window.removeEventListener('storage', handleLogout);
    };
  }, [])

  return (
    <>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route element={<HeaderLayout />}>
              <Route path='/plans' element={<Plan />} />
              <Route path='/assessment' element={<Assessment />} />
              <Route path='/profile' element={<Protected><Profile /></Protected>} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/verify-user' element={<VerifyUser />} />
              <Route path='/payment' element={<Protected><Payment /></Protected>} />
              <Route path='/pending-reports' element={<PendingReportList />} />
              <Route path='/pending-reports/:id' element={<EditPendingReport />} />
              <Route path='/' element={<Home />} />
              <Route path='*' element={<Page404 />} />
            </Route>
          </Routes>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </Router>
    </>
  );
}

export default App;
