import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { PLANS_ARRAY } from '../constants/paymentConstants'
import '../styles/Plan.scss'
import BraintreeDropIn from '../components/BraintreeDropIn/BraintreeDropIn';
import { useSelector } from 'react-redux';

const Payment = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const plan = searchParams.get("plan");
    const yearly = searchParams.get("yearly");
    const redirect = useNavigate();

    const payRef = useRef(null);


    
    const { user } = useSelector(state => state.Auth)

    const onPayment = () => {
        payRef.current && payRef.current.click();
    }



    const [choice, setChoice] = useState("")
    const [isYearlyPlan, setIsYearlyPlan] = useState(false)

    useEffect(() => {
        if (plan) {
            setChoice(plan)
        }
        if (yearly) {
            setIsYearlyPlan(yearly === "true")
        }
    }, [plan, yearly])

    const onPaymentCompleted = () => {
        setTimeout(() => {
            redirect("/profile");
        }, 2000);
    }


    return (
        <div className="container-fluid">
            <div className="row mb-4">
                <div className="col-12 col-md-5">
                    <div className="d-flex">
                        <Link to='/dashboard'>
                            <i className='bx bx-chevron-left back-btn'></i>
                        </Link>
                        <div>
                            <h1>Choose Your Plan</h1>
                            <p>Make the payment & you are good to go...</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <div className="d-flex justify-content-end mb-5">
                        <button className="send-btn header-btn btn-login mx-2" onClick={onPayment}>
                            {user.subscriptionId !== "" ? "Update Plan" : "Pay Now"}
                        </button>
                        <button className="send-btn header-btn btn-start mx-2" >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>


            <div className="d-flex justify-content-between mt-4 gap-4">
                <div className="selected-plan">
                    {PLANS_ARRAY?.filter((item) => item.NAME.toLowerCase() === plan)?.map((item) => (
                        <>
                            <div className="payment-card">
                                <div className="d-flex align-items-center">
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id={`payent-radio-${item.NAME}`}
                                            onChange={() => setChoice(item.NAME)}
                                            checked={item.NAME === choice}
                                        />
                                        <label
                                            htmlFor={`payent-radio-${item.NAME}`}
                                            className="radio-label"
                                        ></label>
                                    </div>
                                    <div>
                                        <h2 className="m-0 mb-4">{item.NAME}</h2>
                                        <div className="d-flex align-items-center mb-3">
                                            <h1 className="payment-card__price">
                                                ${isYearlyPlan ? item.YEARLY_PRICE : item.MONTHLY_PRICE}
                                            </h1>
                                            <span className="payment-card__label">
                                                /{isYearlyPlan ? " Year" : " Month"}
                                            </span>
                                        </div>

                                        {/* add monthly price also  */}

                                    </div>
                                </div>
                                <div className="payment-card__plan-card">

                                    <ul className="payment-card__list mb-4">
                                        {item.BULLET_POINTS?.map((point, index) => (
                                            <li key={index} className="payment-card__list-item">
                                                <i className='bx bxs-check-circle bullet-check'></i>
                                                {point}
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            </div>

                        </>
                    ))}
                </div >
                <div className="d-flex justify-content-center w-100 m-auto">

                    <div className="dropin">
                        <BraintreeDropIn
                            plan={{
                                choice, isYearlyPlan
                            }}
                            user={user}
                            onPaymentCompleted={onPaymentCompleted}
                            ref={payRef}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Payment