import axios from 'axios';
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST

export const addAssessment = (assessment, redirectToDashboard) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ADD_ASSESSMENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/assessment/end-user`, assessment, config);
            redirectToDashboard();
            toast.success('Assessment added successfully!');
            dispatch({ type: 'ADD_ASSESSMENT_SUCCESS', assessment: data });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'ADD_ASSESSMENT_FAILURE', error: error.message });
        }
    }
}

export const updateAssessment = (assessment, id, redirectToDashboard) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_ASSESSMENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/assessment/${id}`, assessment, config);
            redirectToDashboard();
            toast.success('Assessment updated successfully!');
            dispatch({ type: 'UPDATE_ASSESSMENT_SUCCESS', assessment: data });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'UPDATE_ASSESSMENT_FAILURE', error: error.message });
        }
    }
}



export const getAssessments = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ASSESSMENTS_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/assessment/end-user`, config);
            dispatch({ type: 'GET_ASSESSMENTS_SUCCESS', assessments: data });
        } catch (error) {
            dispatch({ type: 'GET_ASSESSMENTS_FAILURE', error: error.message });
        }
    }
}


export const deleteAssessment = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'DELETE_ASSESSMENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            await axios.delete(`${API_URL}/assessment/${id}`, config);
            toast.success('Assessment deleted successfully!');
            dispatch({ type: 'DELETE_ASSESSMENT_SUCCESS', id });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'DELETE_ASSESSMENT_FAILURE', error: error.message });
        }
    }
}


export const resetTimeLimit = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'RESET_TIME_LIMIT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            await axios.post(`${API_URL}/auth/reset-time-limit`, {}, config);
            dispatch({ type: 'RESET_TIME_LIMIT_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'RESET_TIME_LIMIT_FAILURE', error: error.message });
        }
    }
}

export const resetReviewLimit = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'RESET_REVIEW_LIMIT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            await axios.post(`${API_URL}/auth/reset-review-limit`, {}, config);
            dispatch({ type: 'RESET_REVIEW_LIMIT_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'RESET_REVIEW_LIMIT_FAILURE', error: error.message });
        }
    }
}



export const requestReviewReport = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'REQUEST_REVIEW_REPORT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/assessment/patient-request-review/${id}`, {}, config);
            if (data.message) {
                toast.error(data.message);
            }
            else {
                dispatch({ type: 'REQUEST_REVIEW_REPORT_SUCCESS', assessment: data });
                toast.success('Review request sent successfully!');
            }
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'REQUEST_REVIEW_REPORT_FAILURE', error: error.message });
        }
    }
}