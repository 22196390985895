import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { useSelector } from 'react-redux';


const HeaderLayout = () => {
    const { isAuthenticated } = useSelector(state => state.Auth)
    return (
        <>
            {
                isAuthenticated && <Header />
            }
            <Outlet />
        </>
    )
}


export default HeaderLayout